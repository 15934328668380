import { Container, Title, List } from '@mantine/core';
import React from 'react';

const TermsConditions = () => {
  return (
    <Container size="lg" my="md">
      <Title>Terms & Conditions</Title>
      <List spacing="xs">
        <List.Item>
          Use of this site is provided by Indian Awaz Foundation subject to the
          following of the Terms and conditions as given below.
        </List.Item>
        <List.Item>
          Your use and access to the website constitute acceptance of these
          Terms and Conditions as at the date of your first use of the website.
        </List.Item>
        <List.Item>
          You agree to use the website only for lawful purposes, and in a manner
          which does not infringe the rights, or restrict, or inhibit the use
          and enjoyment of the website by any third party.
        </List.Item>
        <List.Item>
          Indian Awaz Foundation reserves the rights to change these Terms and
          Conditions at any time by posting changes online. Your continued use
          of the website after changes are posted constitutes your acceptance of
          the new Terms and Conditions as modified automatically. You should,
          therefore, review these Terms and Conditions regularly.
        </List.Item>
        <List.Item>
          Indian Awaz Foundation takes no responsibility for the content of
          external internet websites. Following links to any other websites or
          pages shall be at your own risk and Indian Awaz Foundation shall not
          be responsible or liable, directly or indirectly, for any damages
          resulting from the use of such other websites.
        </List.Item>
        <List.Item>
          All intellectual property on the website and the material or
          information it contains including without limitation copyright,
          designs, database rights and trademarks (registered or unregistered)
          are and shall remain the property of Indian Awaz Foundation or its
          third party licensors.
        </List.Item>
        <List.Item>
          Commercial use or publication of all or any item displayed on the site
          without authorization from Indian Awaz Foundation is strictly
          prohibited. Nothing contained in these Terms and Conditions shall be
          construed as conferring any licensee by Indian Awaz Foundation to use
          any item displayed.
        </List.Item>
        <List.Item>
          Materials in the website may be copied for personal use only on the
          condition that all copyright notices and source indications are also
          reproduced, no modifications are made and each relevant item is copied
          in its entirety. Some materials that have been outsourced have been
          published on the website with all the necessary permissions from the
          relevant copyright owners (who are not part of Indian Awaz
          Foundation). All rights are reserved on these materials and permission
          to copy them must be requested. from the individual copyright owners
          (as indicated within these materials). Any communication or material
          that you transmit to, or post on, any public area of the website
          including any data, questions, comments, suggestions, or the like, is
          and will be treated as non-confidential and non-proprietary
          information. Indian Awaz Foundation reserves the right to remove any
          such communication or material from the website at its own discretion.
        </List.Item>
        <List.Item>
          I agree and consent to receive all communications at the mobile number
          provided, even if this mobile number is registered under DND/NCPR list
          under TRAI regulations. I understand that these communications could
          be for promotional and information purposes. And for that purpose, I
          further authorize the Company to share/disclose the information to any
          third-party service provider or any affiliates, group companies, their
          authorized agents, or third-party service providers.
        </List.Item>
        <List.Item>
          If there is any conflict between these Terms and Conditions and rules
          and/or specific terms of use appearing on the Website relating to
          specific material then the latter shall prevail.
        </List.Item>
        <List.Item>
          These Terms and Conditions shall be governed and construed in
          accordance with the laws of India. Any disputes shall be subject to
          the non-exclusive jurisdiction of the Indian Courts.
        </List.Item>
        <List.Item>
          If these Terms and Conditions are not accepted in full, the use of the
          website must be terminated immediately. Visitors acknowledge that
          visiting this site is an implicit acceptance of these 'Terms and
          Conditions' on their part. Accepting 'Terms and Conditions' means an
          implicit acceptance of our Disclaimer and Privacy Policy.
        </List.Item>
      </List>
    </Container>
  );
};

export default TermsConditions;
