import React from 'react';
import {
  createStyles,
  Header,
  Group,
  Button,
  Divider,
  Burger,
  Drawer,
  ScrollArea,
  Container,
  Paper,
} from '@mantine/core';
import logo from '../static/aid-for-assam-logo.png';
import { useDisclosure } from '@mantine/hooks';
import { NavLink, Link } from 'react-router-dom';

const useStyles = createStyles(theme => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,

    [theme.fn.smallerThan('sm')]: {
      height: 42,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    }),
  },

  subLink: {
    width: '100%',
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    borderRadius: theme.radius.md,

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
    }),

    '&:active': theme.activeStyles,
  },

  dropdownFooter: {
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[7]
        : theme.colors.gray[0],
    margin: -theme.spacing.md,
    marginTop: theme.spacing.sm,
    padding: `${theme.spacing.md}px ${theme.spacing.md * 2}px`,
    paddingBottom: theme.spacing.xl,
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
  },

  hiddenMobile: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  hiddenDesktop: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  donateNow: {
    fontSize: theme.fontSizes.sm,
    backgroundColor: theme.colors[theme.primaryColor][6],
    padding: theme.spacing.sm,
    color: '#fff',
    fontWeight: 700,
    textDecoration: 'none',

    ':hover': {
      backgroundColor: theme.colors[theme.primaryColor][5],
    },
  },
}));

const HeaderMenu = () => {
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const { classes, theme } = useStyles();

  let activeStyle = {
    textDecoration: 'underline',
    fontWeight: 700,
    color: theme.colors.red[5],
  };

  return (
    <Paper>
      <Header height={70}>
        <Container size="lg" sx={{ height: '100%' }}>
          <Group position="apart" sx={{ height: '100%' }}>
            <Link to=".">
              <img src={logo} alt="Aid for Assam" title="Aid for Assam" />
            </Link>
            <Group
              sx={{ height: '100%' }}
              spacing={0}
              className={classes.hiddenMobile}
            >
              <NavLink
                to="."
                className={classes.link}
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                Home
              </NavLink>
              <NavLink
                to="/about-us"
                className={classes.link}
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                About
              </NavLink>
              <NavLink
                to="/contact"
                className={classes.link}
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                Contact
              </NavLink>
              <NavLink
                to="/become-volunteer"
                className={classes.link}
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                Become Volunteer
              </NavLink>
            </Group>

            <Group className={classes.hiddenMobile}>
              <Link to="/donate-now" className={classes.donateNow}>
                Donate Now
              </Link>
            </Group>

            <Burger
              opened={drawerOpened}
              onClick={toggleDrawer}
              className={classes.hiddenDesktop}
            />
          </Group>
        </Container>
      </Header>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        title="Menu"
        className={classes.hiddenDesktop}
        zIndex={1000000}
      >
        <ScrollArea sx={{ height: 'calc(100vh - 60px)' }} mx="-md">
          <Divider
            my="sm"
            color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'}
          />

          <a href="." className={classes.link}>
            Home
          </a>
          <a href="/about-us" className={classes.link}>
            About
          </a>
          <a href="/contact" className={classes.link}>
            Contact
          </a>
          <a href="/become-volunteer" className={classes.link}>
            Become Volunteer
          </a>

          <Divider
            my="sm"
            color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'}
          />

          <Group position="center" grow pb="xl" px="md">
            <Button component="a" href="donate-now">
              Donate
            </Button>
          </Group>
        </ScrollArea>
      </Drawer>
    </Paper>
  );
};

export default HeaderMenu;
