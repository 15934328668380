import React from 'react';
import {
  createStyles,
  Container,
  Title,
  Button,
  Group,
  Text,
} from '@mantine/core';
import SliderImg from './SliderImg';
const useStyles = createStyles(theme => ({
  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing.xl * 4,
    paddingBottom: theme.spacing.xl * 4,
    [theme.fn.smallerThan('md')]: {
      paddingTop: theme.spacing.xl * 2,
      flexDirection: 'column',
      paddingBottom: theme.spacing.xl * 2,
    },
  },

  content: {
    maxWidth: 480,
    marginRight: theme.spacing.xl * 3,
    [theme.fn.smallerThan('md')]: {
      maxWidth: '100%',
      marginRight: 0,
      marginBottom: theme.spacing.xl * 3,
    },
  },

  title: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontSize: 44,
    lineHeight: 1.2,
    [theme.fn.smallerThan('xs')]: {
      fontSize: 28,
    },
  },

  control: {
    [theme.fn.smallerThan('xs')]: {
      flex: 1,
    },
  },

  highlight: {
    color: theme.colors[theme.primaryColor][4],
  },
  lightFont: {
    fontWeight: 400,
  },
}));

const Hero = () => {
  const { classes } = useStyles();
  return (
    <div>
      <Container size="lg">
        <div className={classes.inner}>
          <div className={classes.content}>
            <Title className={classes.title}>
              <span className={classes.highlight}>Assam</span>{' '}
              <span className={classes.lightFont}>
                needs help in this floods,
              </span>{' '}
              <span className={classes.highlight}>and you can</span>
            </Title>
            <Text mt="md">
              Aid for Assam has held relief providing campaigns for different
              parts of the state devastated and crippled by the Brahmaputra
              floods.
            </Text>
            <Group mt={30}>
              <Button
                component="a"
                href="/donate-now"
                radius="xl"
                size="md"
                className={classes.control}
              >
                Donate Now
              </Button>
              <Button
                component="a"
                href="/about-us"
                variant="default"
                radius="xl"
                size="md"
                className={classes.control}
              >
                Learn More
              </Button>
            </Group>
          </div>
          <SliderImg />
        </div>
      </Container>
    </div>
  );
};

export default Hero;
