import React from 'react';
import { Container, Text, List, Title } from '@mantine/core';

const PrivacyPolicy = () => {
  return (
    <Container size="lg" my="md">
      <Title>Privacy policy</Title>
      <Text component="p">
        Indian Awaz Foundation is grateful to all of its supporters including
        those who contribute time, material and/or money to help us continue
        doing our work. Indian Awaz Foundation maintains strict donor privacy
        policies to protect the integrity and privacy of personal information
        gathered from our supporters and visitors through all of our
        communication channels, as well as from visitors to our Websites and
        those who donate through them.
      </Text>
      <Text component="p">
        Indian Awaz Foundation does not sell, exchange or rent your personal
        information to any organization or individual. We will not divulge
        personal information to any other organization or individual other than
        that necessary to transact, fulfill and or account for online donations
        that you have authorized.
      </Text>
      <Text component="p">
        Indian Awaz Foundation will only use your phone number and email address
        if we need to verify information or to thank you for your contribution,
        ask for your opinion, request your assistance, or invite you to a
        special event.
      </Text>

      <Title order={2}>What information do we collect?</Title>
      <Text component="p">
        When you make a donation, attend an event or volunteer with Indian Awaz
        Foundation, we may ask for information such as your name, address, email
        address, phone number and other relevant information.
      </Text>
      <Text component="p">
        Indian Awaz Foundation uses cookies to facilitate authentication and
        personalization across our web systems. A cookie is a small amount of
        data that is sent to your Web browser from a Web server and stored on
        your computer's hard drive. Indian Awaz Foundation uses only anonymous
        information (such as IP address) to track how our online visitors use
        our website. This data is sometimes stored using cookies.
      </Text>
      <Title order={2}>How do we use your information?</Title>
      <Text>
        Indian Awaz Foundation uses personally identifiable information you
        provide about yourself:
      </Text>
      <List>
        <List.Item>To process your donation</List.Item>
        <List.Item>To send you a receipt for your donation</List.Item>
        <List.Item>
          To send you a thank you note for your donation or volunteering
        </List.Item>
        <List.Item>
          To respond to your questions or comments about Indian Awaz Foundation
        </List.Item>
        <List.Item>
          To send you additional information about Indian Awaz Foundation
          Projects
        </List.Item>
        <List.Item>
          To send you invitations for upcoming events that may interest you
        </List.Item>
      </List>
      <Text component="p">
        Indian Awaz Foundation uses non-identifying basic information (aggregate
        data) to analyze site use and to improve the content and design of the
        site. Indian Awaz Foundation may share aggregate data such as how many
        people attended an event, how many volunteers, average donation amount
        etc with sponsors and partners. These statistics will not include any
        personally identifying information. Our website has stringent security
        measures in place to protect the loss, misuse and alteration of the
        information under our control.
      </Text>
      <Text component="p">
        Indian Awaz Foundation will not use personal information in any way
        other than as described under Terms & Conditions. Exceptions to these
        are limited to sharing personal information to cooperate with law
        enforcement officials.
      </Text>
      <Title order={2}>Credit / Debit Card Security</Title>
      <Text component="p">
        Indian Awaz Foundation is grateful for the donations that help us
        continue our valuable work. When you donate to Indian Awaz Foundation
        online, your card information is secured; your credit card number is
        used only for that particular transaction and is not stored. If you
        allow us to store your credit card or bank information for future use
        such as recurring donation, such information will be stored by the
        payment services financial institution. Indian Awaz Foundation only uses
        financial institutions that adheres to the industry standards for
        storing such information.
      </Text>
      <Title order={2}>Is donation tax-deductible?</Title>
      <Text component="p">
        Yes, it is. All cash gifts to Indian Awaz Foundation are Tax Deductible
        under 80G.To claim tax exemption, please keep the donation receipt as
        your official record.
      </Text>
      <Text component="p">
        A donation receipt will be sent to you at the email address that you
        would have provided while making donations, as soon as your donation is
        processed.
      </Text>
      <Title order={2}>No liability</Title>
      <Text component="p">
        Our Website has links to other websites that may collect identifiable
        information about you. Indian Awaz Foundation cannot ensure the privacy
        practices of other sites and are not responsible for any damages due to
        them.
      </Text>
      <Title order={2}>Refund Policy</Title>
      <Text component="p">
        Once you make donation to Indian Awaz Foundation, it can't be refunded.
      </Text>
      <Text component="p">
        By using the website or by providing the information, you consent to the
        collection and the use of the information you disclose on the website in
        accordance with this Privacy Policy and you shall be bound by the same.
      </Text>
      <Text component="p">
        For any questions or comments about these Terms & Conditions or to
        update any personally identifiable information on our website, please
        contact us by sending an email to{' '}
        <Text
          component="a"
          c="red.4"
          href="mailto:foundationindianawaz@gmail.com"
        >
          foundationindianawaz@gmail.com
        </Text>{' '}
        .
      </Text>
      <Text component="p">
        Indian Awaz Foundation reserves the right to change the Privacy Policy
        at any time, to make sure you are aware, please review this policy
        periodically.
      </Text>
    </Container>
  );
};

export default PrivacyPolicy;
