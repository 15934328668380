import React from 'react';
import { Image } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import sliderOne from '../static/img/aid-for-assam-home-slider-1.png';
import sliderTwo from '../static/img/aid-for-assam-home-slider-2.png';
import sliderThree from '../static/img/aid-for-assam-home-slider-3.png';

const SliderImg = () => {
  return (
    <Carousel
      sx={{ maxWidth: 500 }}
      mx="auto"
      withIndicators
      height={'500'}
      loop
    >
      <Carousel.Slide>
        <Image src={sliderOne} />
      </Carousel.Slide>
      <Carousel.Slide>
        <Image src={sliderTwo} />
      </Carousel.Slide>
      <Carousel.Slide>
        <Image src={sliderThree} />
      </Carousel.Slide>
    </Carousel>
  );
};

export default SliderImg;
