import React from 'react';
import { createStyles, Title, Text, Container } from '@mantine/core';
import { List, ThemeIcon } from '@mantine/core';
import { IconPhone, IconClock, IconMail } from '@tabler/icons';

const useStyles = createStyles(theme => ({
  wrapper: {
    position: 'relative',
    paddingTop: 120,
    paddingBottom: 80,

    '@media (max-width: 755px)': {
      paddingTop: 80,
      paddingBottom: 60,
    },
  },

  inner: {
    position: 'relative',
    zIndex: 1,
  },

  dots: {
    position: 'absolute',
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[5]
        : theme.colors.gray[1],

    '@media (max-width: 755px)': {
      display: 'none',
    },
  },

  dotsLeft: {
    left: 0,
    top: 0,
  },

  title: {
    textAlign: 'center',
    fontWeight: 800,
    fontSize: 40,
    letterSpacing: -1,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    marginBottom: theme.spacing.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    '@media (max-width: 520px)': {
      fontSize: 28,
      textAlign: 'left',
    },
  },

  highlight: {
    color:
      theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6],
  },

  description: {
    textAlign: 'center',

    '@media (max-width: 520px)': {
      textAlign: 'left',
      fontSize: theme.fontSizes.md,
    },
  },

  controls: {
    marginTop: theme.spacing.lg,
    display: 'flex',
    justifyContent: 'center',

    '@media (max-width: 520px)': {
      flexDirection: 'column',
    },
  },

  control: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing.md,
    },

    '@media (max-width: 520px)': {
      height: 42,
      fontSize: theme.fontSizes.md,

      '&:not(:first-of-type)': {
        marginTop: theme.spacing.md,
        marginLeft: 0,
      },
    },
  },
}));

const Contact = () => {
  const { classes } = useStyles();
  return (
    <Container className={classes.wrapper} size={1400}>
      <div className={classes.inner}>
        <Title className={classes.title}>
          We'll be{' '}
          <Text component="span" className={classes.highlight} inherit>
            happy to connect.
          </Text>
        </Title>

        <Container p={0} size={600}>
          <Text
            size="md"
            mb="md"
            color="dimmed"
            className={classes.description}
          >
            For any enquiries or information, you may reach us via following.
          </Text>
          <List spacing="xs" size="sm" center>
            <List.Item
              icon={
                <ThemeIcon color="red" size={28} radius="sm">
                  <IconMail size={16} />
                </ThemeIcon>
              }
            >
              <Text fw={500} sx={{ display: 'inline-block' }}>
                E-mail:
              </Text>{' '}
              aidforassam@gmail.com
            </List.Item>
            <List.Item
              icon={
                <ThemeIcon color="red" size={28} radius="sm">
                  <IconPhone size={16} />
                </ThemeIcon>
              }
            >
              <Text fw={500} sx={{ display: 'inline-block' }}>
                Call:
              </Text>{' '}
              <a href="tel:+917002052581">+91 70020-52581</a>
            </List.Item>
            <List.Item
              icon={
                <ThemeIcon color="red" size={28} radius="sm">
                  <IconClock size={16} />
                </ThemeIcon>
              }
            >
              <Text fw={500} sx={{ display: 'inline-block' }}>
                Timing:
              </Text>{' '}
              Monday to Friday 9:00AM-7:00PM
            </List.Item>
          </List>
        </Container>
      </div>
    </Container>
  );
};

export default Contact;
