import React from 'react';
import {
  createStyles,
  Container,
  Title,
  Button,
  Group,
  Text,
} from '@mantine/core';
import SliderImg from '../components/SliderImg';

const useStyles = createStyles(theme => ({
  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing.xl * 4,
    [theme.fn.smallerThan('md')]: {
      paddingTop: theme.spacing.xl * 2,
      flexDirection: 'column',
    },
  },

  content: {
    maxWidth: 480,
    marginRight: theme.spacing.xl * 3,
    [theme.fn.smallerThan('md')]: {
      maxWidth: '100%',
      marginRight: 0,
      marginBottom: theme.spacing.xl * 3,
    },
  },

  title: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontSize: 44,
    lineHeight: 1.2,
    [theme.fn.smallerThan('xs')]: {
      fontSize: 28,
    },
  },

  control: {
    [theme.fn.smallerThan('xs')]: {
      flex: 1,
    },
  },

  highlight: {
    color: theme.colors[theme.primaryColor][4],
  },
  lightFont: {
    fontWeight: 400,
  },
}));

const BecomeVolunteer = () => {
  const { classes } = useStyles();
  return (
    <Container size="lg">
      <div className={classes.inner}>
        <div className={classes.content}>
          <Title className={classes.title}>
            <span className={classes.lightFont}>You can make big change,</span>{' '}
            <span className={classes.highlight}>become a Volunteer.</span>{' '}
            <span className={classes.lightFont}>Assam needs your help.</span>
          </Title>
          <Text mt="md">Join us to help the people of Assam.</Text>
          <Group mt={30}>
            <Button radius="xl" size="md" className={classes.control}>
              Join us now
            </Button>
          </Group>
        </div>
        <SliderImg />
      </div>
    </Container>
  );
};

export default BecomeVolunteer;
