// import { MantineThemeOverride } from '@mantine/core';

const theme = {
  colors: {
    red: [
      '#ffe4e4',
      '#fdb8b8',
      '#f58a8a',
      '#f05c5c',
      '#ea2f2f',
      '#d01515',
      '#a30e0f',
      '#75080a',
      '#480304',
      '#1f0000',
    ],
  },
  primaryColor: 'red',
  primaryShade: 6,
  fontFamily: '"Inter", sans-serif',
  fontSizes: {
    xs: 14,
    sm: 16,
    md: 18,
    lg: 20,
    xl: 22,
  },
  components: {
    Title: {
      styles: {
        root: {
          fontFamily: '"Inter", sans-serif',
        },
      },
    },
  },
};

export default theme;
